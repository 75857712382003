import React, {useEffect} from "react";
import type {HeadFC, PageProps} from "gatsby"
import Layout from "@components/layout";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";

const OfertaPage: React.FC<PageProps> = ({data, pageContext}: any) => {

    const isClient = typeof window !== 'undefined';

    useEffect(() => {
        console.log('data', data);
    }, []);

    return (
        <Layout>
            <CommonPageHeader title="Oferta" subtitle={''} />
            <section className="oferta-arena pt-115 pb-70">
                {data.allStrapiOferta.nodes.map((data, key) => (
                    <div className={"container-fluid pt-70 pb-70 oferta-container" + (key%2===0?" odd" : " even")} key={key}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"container"}>
                                    <div className={"row align-items-center " + (key % 2 === 0 ? ' flex-row-reverse' : '' )}  >
                                        <div className="col-md-6 col-12">
                                            <div>
                                                <h1
                                                    data-aos="fade-right"
                                                    data-aos-offset="150"
                                                    data-aos-easing="ease-in-sine"
                                                    data-aos-once="true"
                                                >{data.OfertaData.name}</h1>
                                            </div>
                                            <div className={"description"}
                                                 data-aos="fade-right"
                                                 data-aos-offset="150"
                                                 data-aos-easing="ease-in-sine"
                                                 data-aos-once="true"
                                            >
                                                { data.OfertaData.description }
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className={"oferta-image-wrapper"}
                                                 data-aos="fade-right"
                                                 data-aos-offset="300"
                                                 data-aos-easing="ease-in-sine"
                                                 data-aos-once="true"
                                            >
                                                <GatsbyImage
                                                    image={getImage(data?.OfertaData.images?.[0].localFile || null)}
                                                    alt={data?.OfertaData.images?.[0].alternativeText}
                                                    className="mt-6 gatsby-image-wrapper-fluid oferta-image-holder"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query {
        allStrapiOferta {
            nodes {
                ShowOnHomepage
                Order
                id
                strapi_id
                publishedAt
                createdAt
                OfertaData {
                    name
                    description
                    images {
                        mime
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(aspectRatio: 1.5, height: 337)
                            }
                        }
                    }
                }   
            }
        }
    }
`

export default OfertaPage

export const Head: HeadFC = () => <title>Oferta - Fizjoproject Kiekrz</title>
